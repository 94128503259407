/**
 * Formats a given input date string into the format "dd.mm.yyyy".
 *
 * @param {string} inputDateString - The input date string to be formatted.
 * @return {string} The formatted date string in the format "dd.mm.yyyy".
 */
const getFormattedDate = (inputDateString) => {
    if (!inputDateString) {
        return '';
    }

    const inputDate = new Date(inputDateString);

    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based, so we add 1.
    const year = inputDate.getFullYear();

    return `${day}.${month}.${year}`;
}

/**
 * @param {string} email
 */
const emailLink = (email) => {
    if (!email) {
        return false;
    }

    return {
        email,
        link: `mailto: ${email}`
    };
};

/**
 * @param {string} phone
 */
const phoneLink = (phone) => {
    if (!phone) {
        return false;
    }

    return {
        number: phone,
        link: `tel: ${phone.replaceAll(' ', '').replaceAll('-', '')}`
    };
};

/**
 * @param {string} dateString
 * @param {string} lang
 * @param {boolean} includesTime
 *
 */
const getDateFormat = (dateString, lang, includesTime) => {
    const date = new Date(dateString);

    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit'
    }

    const defaultOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    };

    const options = includesTime
        ? {
            ...defaultOptions,
            ...timeOptions
        }
        : defaultOptions;

    return date.toLocaleString(lang, options);
}

/**
 * Truncates the text content of the last list item in an HTML string.
 *
 * @param {string} htmlString - The HTML string to be processed.
 * @return {string} The modified HTML string.
 */
const getTruncatedList = (htmlString) => {
    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;

    // Get the last <li> element
    const list = tempElement.querySelector('ul');
    const lastLi = list.lastElementChild;

    if (lastLi) {
        // Get the text content and remove the last word
        const text = lastLi.textContent.trim();
        const lastWordIndex = text.lastIndexOf(' ');
        const newText = text.substring(0, lastWordIndex);

        // Update the <li> with the new text
        lastLi.textContent = newText + '...';

        (lastLi.textContent === '...') && list.removeChild(lastLi);

        // Return the modified HTML string
        return tempElement.innerHTML;
    }

    // Return the original HTML string if no <li> is found
    return htmlString;
}

export {
    emailLink,
    phoneLink,
    getDateFormat,
    getFormattedDate,
    getTruncatedList
};
